import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { appKey, serverUrl } from "../Controllers/Config";

const ConfirmPaymentComponent = ({ checkoutRequestID, onProcess }) => {
  const [alertData, setAlertData] = useState({
    message: "STK Push sent successfully. Processing payment...",
    color: "info",
  });

  const [loading, setLoading] = useState(true); // Loading state
  const token = localStorage.getItem("hp_jwt");
  const confirmPayment = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/mpesa/stkQuery`,
        {
          CheckoutRequestID: checkoutRequestID,
        },
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        }
      );

      const { errorCode, errorMessage, ResultDesc } = response.data;

      if (errorCode === "500.001.1001") {
        setAlertData({
          message: "The transaction is being processed",
          color: "info",
        });
        setTimeout(confirmPayment, 1000);
      } else if (response.data.ResultCode === "0") {
        setAlertData({
          message: "Payment processing complete",
          color: "success",
        });
        onProcess("paid");
      } else {
        setAlertData({
          message: `${ResultDesc}`,
          color: "warning",
        });
        onProcess("");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      setAlertData({
        message: "An error occurred while processing the request.",
        color: "danger",
      });
      onProcess("");
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  useEffect(() => {
    confirmPayment();
  }, [checkoutRequestID]);

  return (
    <div className={`mt-2 alert alert-${alertData.color}`} role="alert">
      {loading ? ( // Conditionally render spinner or message
        <>
          <Spinner animation="border" size="sm" /> {alertData.message}
        </>
      ) : (
        alertData.message
      )}
    </div>
  );
};

export default ConfirmPaymentComponent;
