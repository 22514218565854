import React, { useState } from "react";
import {  formatAmount, placeholderImage } from "../Controllers/Config";
import { Link } from "react-router-dom";

const ResultCard = ({ result ,options}) => {
  console.log("options", options);
  const [fetching, setFetching] = useState(false);
  const propertyinfo = result.propertyData;

  function truncateDescription(description, maxLength) {
    if (description && description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  }

  

  // Function to find the lowest price
  const findLowestPrice = () => {
    if (result.rooms.length > 0) {
      let lowestPrice = Number.MAX_VALUE;
      let lowestPrices = Number.MAX_VALUE;
      let lowestPricec = "";
      result.rooms.forEach((room) => {
        room.ratePlans.forEach((ratePlan) => {
          if (ratePlan.prices.net.searchCurrency < lowestPrice) {
            lowestPrices = ratePlan.prices.net.searchCurrency;
            lowestPricec = ratePlan.prices.net.currency;
            lowestPrice = ratePlan.prices.net.price;
          }
        });
      });
      
      return `${lowestPricec} ${formatAmount(lowestPrice)} (KES ${formatAmount(
        lowestPrices
      )})`;
    }
    return null;
  };




  return (
    <div className="col mb-4">
      <div className="card resultcard">
        <img
          src={
            propertyinfo
              ? propertyinfo.images
                ? propertyinfo.images[0].uri
                : placeholderImage
              : placeholderImage
          }
          className="card-img-top result-img"
          alt="Property"
        />
        <div className="card-body">
          <div className="result-content">
            <h5 className="card-title result-title">
              {result.propertyInfo.name}
            </h5>
            <div>
              <span className="badge text-bg-dark">
                {result.propertyInfo.propertyTypeName}
              </span>
              &nbsp;
              {[...Array(result.propertyInfo.starRating)].map(
                (starRating, i) => (
                  <i key={i} className="fas fa-star text-warning"></i>
                )
              )}
            </div>
            <p className="mb-2 fw-bold">
              From{" "}
              {result.rooms.length > 0 && findLowestPrice()
                ? findLowestPrice()
                : ""}
            </p>
            <p className="result-location mb-2">
              <i className="fas fa-map-marker-alt"></i>&nbsp;
              {propertyinfo
                ? propertyinfo.location
                  ? propertyinfo.location.address
                  : ""
                : ""}
            </p>
            <p className="text-small">
              {propertyinfo
                ? propertyinfo.descriptions && propertyinfo.descriptions[0]
                  ? truncateDescription(
                      propertyinfo.descriptions[0].description,
                      100
                    )
                  : ""
                : ""}
            </p>
          </div>
          <div className="result-button">
            {fetching ? (
              <p>loading</p>
            ) : (
              <Link
                to={{
                  pathname: `/booking/${result.propertyId}`,
                  search: `?name=${result.propertyInfo.name}&checkIn=${options.checkIn}&nationality=${options.customerNationality}&nights=${options.nights}&guests=${options.guests}`,
                }}
                className="btn btn-mod btn-border btn-medium animation-one"
              >
                <span>Reserve</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
