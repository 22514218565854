import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const UpdateGuests = ({ roomGuests }) => {
  const [roomData, setRoomData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Initialize roomData with roomGuests
    const initializedRoomData = roomGuests.map((room, index) => ({
      title: `Room ${index + 1}`,
      adults: room.adults || 1,
      children: room.children ? room.children.length : 0,
      childrenAges: room.children || [],
    }));
    setRoomData(initializedRoomData);
  }, [roomGuests]);

  const addRoom = () => {
    const newRoom = {
      title: `Room ${roomData.length + 1}`,
      adults: 1,
      children: 0,
      childrenAges: [],
    };
    setRoomData([...roomData, newRoom]);
  };

  const removeRoom = (index) => {
    if (roomData.length > 1) {
      const updatedRooms = roomData.filter((_, i) => i !== index);
      setRoomData(
        updatedRooms.map((room, i) => ({ ...room, title: `Room ${i + 1}` }))
      );
    }
  };

  const handleAdultsChange = (value, index) => {
    const updatedRooms = [...roomData];
    updatedRooms[index].adults = parseInt(value, 10);
    setRoomData(updatedRooms);
  };

  const handleChildrenChange = (value, index) => {
    const updatedRooms = [...roomData];
    const numChildren = parseInt(value, 10);
    updatedRooms[index].children = numChildren;
    updatedRooms[index].childrenAges = Array.from(
      { length: numChildren },
      (_, i) => updatedRooms[index].childrenAges[i] || 0
    );
    setRoomData(updatedRooms);
  };

  const handleChildAgeChange = (age, childIndex, roomIndex) => {
    const updatedRooms = [...roomData];
    updatedRooms[roomIndex].childrenAges[childIndex] = parseInt(age, 10);
    setRoomData(updatedRooms);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSubmit = () => {
    const currentUrl = new URL(window.location);
    const guestsParam = roomData
      .map((room) => {
        if (room.children > 0) {
          return `${room.adults}-${room.childrenAges.join(",")}`;
        } else {
          return `${room.adults}`;
        }
      })
      .join(".");
    currentUrl.searchParams.set("guests", guestsParam);

    window.history.pushState({}, "", currentUrl);
    window.location.reload();

    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Update Guests
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Guests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {roomData.map((room, roomIndex) => (
            <div key={roomIndex} className="mb-3">
              <div className="d-flex justify-content-between align-items-center mt-1">
                <h5 className="fw-bold">{room.title}</h5>
                {roomData.length > 1 && (
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => removeRoom(roomIndex)}
                  >
                    Remove {room.title}
                  </Button>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId={`adults-${roomIndex}`}>
                    <Form.Label>Adults</Form.Label>
                    <Form.Control
                      type="number"
                      value={room.adults}
                      min={1}
                      max={10}
                      onChange={(e) =>
                        handleAdultsChange(e.target.value, roomIndex)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId={`children-${roomIndex}`}>
                    <Form.Label>Children</Form.Label>
                    <Form.Control
                      type="number"
                      value={room.children}
                      min={0}
                      max={10}
                      onChange={(e) =>
                        handleChildrenChange(e.target.value, roomIndex)
                      }
                    />
                  </Form.Group>
                </div>
              </div>
              {room.children > 0 && (
                <div className="row">
                  {[...Array(room.children)].map((_, childIndex) => (
                    <div key={childIndex} className="col-md-4">
                      <Form.Group
                        controlId={`child-age-${roomIndex}-${childIndex}`}
                      >
                        <Form.Label>Child {childIndex + 1} Age</Form.Label>
                        <Form.Control
                          as="select"
                          value={room.childrenAges[childIndex] || 0}
                          onChange={(e) =>
                            handleChildAgeChange(
                              e.target.value,
                              childIndex,
                              roomIndex
                            )
                          }
                        >
                          {[...Array(18)].map((_, index) => (
                            <option key={index} value={index}>
                              {index === 0
                                ? "0 year old"
                                : `${index} year${index > 1 ? "s" : ""} old`}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" className="mt-1" onClick={addRoom}>
            Add Room
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateGuests;
