import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Booking from "./pages/Booking";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import FAQs from "./pages/FAQs";
import usePageTitle from "./components/utils/pagetitle";
import NotFound from "./pages/404";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import MyBookings from "./pages/dashboard/MyBookings";
import MyCancellations from "./pages/dashboard/MyCancellations";
import MyProfile from "./pages/dashboard/MyProfile";
import AuthProvider from "./components/utils/AuthContext";
import MyPayments from "./pages/dashboard/MyPayments";
import ViewBooking from "./pages/dashboard/booking/ViewBooking";
import CancelBooking from "./pages/dashboard/booking/CancelBooking";
import PasswordReset from "./pages/PasswordReset";
import { appKey, serverUrl } from "./components/Controllers/Config";
import axios from "axios";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function Page(props) {
  const titlePrefix = " | HOTEL BOOKING SYSTEM";
  usePageTitle(`${props.title}${titlePrefix}`);
  return (
    <div>
      <Helmet>
        <title>
          {props.title}
          {titlePrefix}
        </title>
        <meta
          name="description"
          content={
            props.description ||
            "Your one-stop solution for hotel booking, providing high-quality service and support for all your travel needs."
          }
        />
        <meta
          name="keywords"
          content={
            props.keywords ||
            "hotel booking, travel, accommodation, vacation, hotels, reservations"
          }
        />
      </Helmet>
      {props.content}
    </div>
  );
}



const App = () => {
  const [searchOptions, setSearchOptions] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [siteData, setSiteData] = useState(null); // State to store site data

  // Fetch site data from the server or local storage
 useEffect(() => {
   const fetchSiteData = async () => {
     try {
       const storedSiteData = localStorage.getItem("siteData");
       if (storedSiteData) {
         const parsedSiteData = JSON.parse(storedSiteData);
         const storedTimestamp = parsedSiteData.timestamp;
         const weekInMilliseconds = 1 * 24 * 60 * 60 * 1000;
         const currentTimestamp = new Date().getTime();

         if (
           currentTimestamp - storedTimestamp < weekInMilliseconds &&
           parsedSiteData.data
         ) {
           setSiteData(parsedSiteData.data);
           return;
         }
       }

       const response = await axios.get(`${serverUrl}/app/locations/`, {
         headers: {
           Authorization: appKey,
         },
       });

       const newSiteData = response.data;

       localStorage.setItem(
         "siteData",
         JSON.stringify({
           data: newSiteData,
           timestamp: new Date().getTime(),
         })
       );

       setSiteData(newSiteData);
     } catch (error) {
       console.error("Error fetching site data:", error);
     }
   };

   fetchSiteData();
 }, []);


  const handleSearchOptions = (options) => {
    setSearchOptions(options);
  };

  const handleSearchData = (data) => {
    setSearchData(data);
  };

  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Navbar />
          <div className="main">
            <ToastContainer autoClose={2000} />
            <Routes>
              <Route
                path="/"
                element={
                  <Page
                    title="Home"
                    content={
                      <Home
                        onSearchOptions={handleSearchOptions}
                        onSearch={handleSearchData}
                        siteData={siteData}
                      />
                    }
                  />
                }
              />
              <Route
                path="/login"
                element={<Page title="Login" content={<Login />} />}
              />
              <Route
                path="/passwordreset/:ResetToken?"
                element={
                  <Page title="Password Restet" content={<PasswordReset />} />
                }
              />
              <Route
                path="/register"
                element={<Page title="Register" content={<Register />} />}
              />
              <Route
                path="/contactus"
                element={
                  <Page
                    title="Contact Us"
                    description="Get in touch with us for any inquiries or support related to our hotel booking services."
                    keywords="contact, support, hotel booking, inquiries"
                    content={<ContactUs />}
                  />
                }
              />
              <Route
                path="/faqs"
                element={
                  <Page
                    title="FAQs"
                    description="Find answers to the most frequently asked questions about our hotel booking services."
                    keywords="faqs, questions, answers, hotel booking, help"
                    content={<FAQs />}
                  />
                }
              />
              <Route
                path="/booking/:propertyId"
                element={
                  <Page
                    title="Booking"
                    description="Book your stay with us and enjoy the best deals and services for your travel needs."
                    keywords="booking, reservation, hotel booking, travel, accommodation"
                    content={
                      <Booking
                        searchOptions={searchOptions}
                        searchData={searchData}
                      />
                    }
                  />
                }
              />
              <Route
                path="*"
                element={
                  <Page
                    title="404 Not Found"
                    description="The page you are looking for does not exist."
                    keywords="404, not found, error"
                    content={<NotFound />}
                  />
                }
              />
              <Route
                path="/dashboard"
                element={<Page title="Dashboard" content={<Dashboard />} />}
              />
              <Route
                path="/dashboard/mybookings"
                element={<Page title="My Bookings" content={<MyBookings />} />}
              />
              <Route
                path="/dashboard/mybookings/:bookingRef/view"
                element={
                  <Page title="View Booking" content={<ViewBooking />} />
                }
              />
              <Route
                path="/dashboard/mybookings/:bookingRef/cancel"
                element={
                  <Page title="Cancel Booking" content={<CancelBooking />} />
                }
              />
              <Route
                path="/dashboard/mycancellations"
                element={
                  <Page
                    title="My Cancellations"
                    content={<MyCancellations />}
                  />
                }
              />
              <Route
                path="/dashboard/mypayments"
                element={<Page title="My Payments" content={<MyPayments />} />}
              />
              <Route
                path="/dashboard/myprofile"
                element={<Page title="My Profile" content={<MyProfile />} />}
              />
            </Routes>
          </div>
          <FloatingWhatsApp
            phoneNumber="+254740096373"
            accountName="Support"
            allowEsc
            allowClickAway
            notification
            notificationSound
          />
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
};

export default App;
