import React, { useState, useEffect } from "react";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { serverUrl, appKey } from "../Controllers/Config";

const ProfileForm = ({ userData }) => {
  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstname || "");
      setMiddleName(userData.middlename || "");
      setLastName(userData.lastname || "");
      setEmail(userData.email || "");
      setPhoneNumber(userData.phoneNumber || "");
      setAddress(userData.address || "");
      setCity(userData.city || "");
      setCountry(userData.country || "");
      setState(userData.state || "");
      setZipcode(userData.zipcode || "");
    }
  }, [userData]);

  const handleUpdateProfile = async () => {
    try {
      setError(null);
      setLoading(true);

      if (!firstname.trim()) {
        setError("Please enter your first name");
        return;
      }

      if (!lastname.trim()) {
        setError("Please enter your last name");
        return;
      }

      if (!email.trim()) {
        setError("Please enter your email address");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      const requestData = {
        firstname,
        middlename,
        lastname,
        email,
        phoneNumber,
        address,
        city,
        country,
        state,
        zipcode,
      };

      const token = localStorage.getItem("hp_jwt");

      const response = await axios.post(
        `${serverUrl}/user/updateprofile`,
        requestData,
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Profile updated successfully:", response.data);
        setError(null);
        setSuccess("Profile updated successfully");
      } else {
        setError(response.data.message || "An error occurred.");
      }
    } catch (error) {
      console.error(
        "Update profile error:",
        error.response?.data?.message || error.message
      );
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4 mb-2">
          <label>First Name:</label>
          <input
            type="text"
            className="form-control"
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Middle Name:</label>
          <input
            type="text"
            className="form-control"
            value={middlename}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Last Name:</label>
          <input
            type="text"
            className="form-control"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Email Address:</label>
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Phone Number (optional):</label>
          <PhoneInput
            defaultCountry="us"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Address (optional):</label>
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>City (optional):</label>
          <input
            type="text"
            className="form-control"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Country (optional):</label>
          <input
            type="text"
            className="form-control"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>State (optional):</label>
          <input
            type="text"
            className="form-control"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
        <div className="form-group col-md-4 mb-2">
          <label>Zipcode (optional):</label>
          <input
            type="text"
            className="form-control"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </div>

        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateProfile}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update Profile"}
          </button>
          {error && <div className="alert alert-danger mt-2">{error}</div>}
          {success && <div className="alert alert-success mt-2">{success}</div>}
        </div>
      </div>
    </form>
  );
};

export default ProfileForm;
