import React, { useState, useEffect, useContext } from "react";
import Room from "./Room";
import {
  serverUrl,
  appKey,
  formatAmount,
  generateCancellationPolicy,
  generateRequestData,
  generateOrderNumber,
} from "../Controllers/Config";
import MpesaForm from "../forms/MpesaForm";
import paymentMethods from "./PaymentMehtods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PersonalInfoStep from "./PersonalInfoStep";
import RoomForm from "../forms/RoomForm";
import { AuthContext } from "../utils/AuthContext";
import AuthForm from "../forms/AuthForm";
import { useParams } from "react-router-dom";
import UpdateGuests from "./UpdateGuests";
import AccordionPayment from "./AccordationPayment";
import { Spinner } from "react-bootstrap";
import ConfirmBookingComponent from "../forms/ConfirmBookingComponent";

const BookingForm = ({
  propertyinfo,
  totalRooms,
  guestData,
  params,
  totalGuests,
  roomGuests,
  orderNo,
}) => {
  const { propertyId } = useParams();
  const { isAuthenticated } = useContext(AuthContext);
  const rooms = propertyinfo.propertyData.rooms.filter((room) =>
    propertyinfo.rooms.some((r) => r.roomId === room.id)
  );
  const queryParams = new URLSearchParams(window.location.search);
  const checkIn = queryParams.get("checkIn");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [numRooms, setNumRooms] = useState(1);
  const [guestsData, setGuestsData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [bookRoomsData, setBookRoomsData] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountS, setTotalAmountS] = useState(0);
  const [totalAmountC, setTotalAmountC] = useState("");
  const [pLoading, setpLoading] = useState("");
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [policyError, setPolicyError] = useState("");

  const handlePolicyCheckboxChange = (event) => {
    setIsPolicyChecked(event.target.checked);
    setPolicyError("");
  };

  useEffect(() => {
    calculateTotalPayable();
    console.log("bookRoomsData", bookRoomsData);
  }, [bookRoomsData]);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    setCurrentStep(2);
  };

  const handlePaymentProcess = (data) => {
    console.log("handlePaymentProcess::", data);
    setpLoading(data);
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNumRoomsChange = (e) => {
    setNumRooms(e.target.value);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setCurrentStep(3);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleGuestsDataChange = (data) => {
    // console.log("guestsData:::", data);
    setGuestsData(data);
  };

  const validateStep = () => {
    const errors = {};

    if (currentStep === 1) {
      if (bookRoomsData.length !== totalRooms) {
        toast.error(
          `Please select exactly ${totalRooms} rooms for your booking.`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }

    if (currentStep === 2) {
      if (!numRooms || numRooms < 1) {
        errors.numRooms = "Number of rooms must be at least 1.";
      }
      console.log("bookRoomsData::", bookRoomsData);
    }

    if (currentStep === 3) {
      if (paymentMethod === "mpesa" && !phoneNumber) {
        errors.phoneNumber = "Please enter your phone number.";
      }
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const calculateTotalPayable = () => {
    let totalPayable = 0;
    let totalPayableS = 0;
    let totalPayableC = "";
    bookRoomsData.forEach((bookroom) => {
      totalPayable += bookroom.expectedPrice.amountMain;
      totalPayableC = bookroom.expectedPrice.currencyMain;
      totalPayableS += bookroom.expectedPrice.amount;
    });
    setTotalAmount(totalPayable);
    setTotalAmountS(totalPayableS);
    setTotalAmountC(totalPayableC);
  };

  return (
    <div className="col-md-12 mt-2">
      <div className="row justify-content-center">
        {/* <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <RoomForm />
            </div>
          </div>
        </div> */}
        <div className="col-md-8 mt-2">
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Step {currentStep}</h2>
            </div>
            <div className="card-body">
              {currentStep === 1 &&
                (rooms && rooms.length > 0 ? (
                  <div>
                    <h3>Select a Room</h3>
                    <div className="list-group">
                      {rooms.map((room) => (
                        <div
                          key={room.id}
                          className={`list-group-item ${
                            validationErrors.room ? "border-danger" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <Room
                                room={room}
                                propertyinfo={propertyinfo}
                                totalRooms={totalRooms}
                                bookRoomsData={bookRoomsData}
                                setBookRoomsData={setBookRoomsData}
                                roomGuests={roomGuests}
                                totalGuests={totalGuests}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {validationErrors.room && (
                      <p className="text-danger mt-2">
                        {validationErrors.room}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="alert alert-warning">
                    {validationErrors.room
                      ? validationErrors.room
                      : "No Rooms available for the guest selection"}
                  </div>
                ))}

              {currentStep === 2 && (
                <>
                  {isAuthenticated ? (
                    <PersonalInfoStep
                      validationErrors={validationErrors}
                      roomGuests={roomGuests}
                      onGuestsDataChange={handleGuestsDataChange}
                    />
                  ) : (
                    <AuthForm />
                  )}
                </>
              )}

              {currentStep === 3 && (
                <>
                  {pLoading === "paid" || pLoading === "complete" ? (
                    <>
                      <div className="alert alert-success">
                        Payment received successfully
                      </div>
                      {pLoading === "paid" && (
                        <>
                          <Spinner animation="border" size="sm" />
                          <p>Kindly wait as your booking is processed</p>
                        </>
                      )}
                      {pLoading === "complete" && (
                        <>
                          <div className="alert alert-success">
                            Booking created successfully
                            <br />
                            <span>Redirecting you to view your booking...</span>
                            <br />
                            <a
                              href={`/dashboard/mybookings/${orderNo}/view`}
                              className="btn btn-primary btn-sm"
                              style={{ marginRight: "10px" }}
                            >
                              View Booking
                            </a>
                          </div>

                          {setTimeout(() => {
                            window.location.href = `/dashboard/mybookings/${orderNo}/view`;
                          }, 5000)}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div>
                        <h3>Preferred Payment Mode</h3>
                        <div className="row">
                          <div className="col-md-12">
                            {propertyId === "199121" ? (
                              <div className="alert alert-info">
                                <h4>
                                  This is a Cerification Property no payment is
                                  required!
                                </h4>
                              </div>
                            ) : (
                              <AccordionPayment
                                bookRoomsData={bookRoomsData}
                                requestData={generateRequestData(
                                  guestsData,
                                  bookRoomsData,
                                  checkIn,
                                  params.checkOut,
                                  propertyId,
                                  totalAmount,
                                  totalGuests
                                )}
                                onProcess={handlePaymentProcess}
                                orderNo={orderNo}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <div className="sticky-top">
            <div className="card">
              <div className="card-body">
                <ul className="list-group">
                  <h3>Pricing Summary</h3>
                  <hr />
                  {bookRoomsData &&
                    bookRoomsData.map((bookroom, index) => (
                      <div key={index}>
                        <p>
                          {bookroom.mydata.room.roomName} -{" "}
                          {bookroom.mydata.plan.name} for{" "}
                          {bookroom.expectedPrice.currencyMain}{" "}
                          {formatAmount(bookroom.expectedPrice.amountMain)}
                          {" (KES "}
                          {formatAmount(bookroom.expectedPrice.amount)}
                          {")"}
                        </p>
                        {/* Taxes Accordion */}
                        <div
                          className="accordion mt-3"
                          id={`taxesAccordion-${index}`}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingTaxes-${index}`}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseTaxes-${index}`}
                                aria-expanded="false"
                                aria-controls={`collapseTaxes-${index}`}
                              >
                                Taxes
                              </button>
                            </h2>
                            <div
                              id={`collapseTaxes-${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`headingTaxes-${index}`}
                              data-bs-parent={`#taxesAccordion-${index}`}
                            >
                              <div className="accordion-body">
                                <ul>
                                  {bookroom.mydata.ratePlanData.prices[
                                    bookroom.mydata.ratePlanData.payment
                                      .chargeType
                                  ]?.taxes.map((tax, taxIndex) => (
                                    <li key={taxIndex} className="text-small">
                                      {tax.description}{" "}
                                      <span className="fw-bold">
                                        {tax.currency}{" "}
                                        {formatAmount(tax.amount)}
                                        {" (KES "}
                                        {formatAmount(tax.searchCurrency)}
                                        {")"}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Fees Accordion */}
                        <div
                          className="accordion mt-3"
                          id={`feesAccordion-${index}`}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingFees-${index}`}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseFees-${index}`}
                                aria-expanded="false"
                                aria-controls={`collapseFees-${index}`}
                              >
                                Fees
                              </button>
                            </h2>
                            <div
                              id={`collapseFees-${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`headingFees-${index}`}
                              data-bs-parent={`#feesAccordion-${index}`}
                            >
                              <div className="accordion-body">
                                <ul>
                                  {bookroom.mydata.ratePlanData.prices.fees.map(
                                    (fee, feeIndex) => (
                                      <li key={feeIndex} className="text-small">
                                        {fee.description}{" "}
                                        <span className="fw-bold">
                                          {fee.currency}{" "}
                                          {formatAmount(fee.amount)}
                                          {" (KES "}
                                          {formatAmount(fee.searchCurrency)}
                                          {")"}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <hr />
                  <h4 className="fw-bold">
                    Total Amount:
                    <span className="text-primary">
                      {" "}
                      {totalAmountC} {formatAmount(totalAmount)}
                      <br></br>
                      {" (KES "}
                      {formatAmount(totalAmountS)}
                      {")"}
                    </span>
                  </h4>
                  {bookRoomsData && bookRoomsData.length > 0 && (
                    <>
                      <hr />
                      <p className="text-muted text-small">
                        <span className="fw-bold">
                          Please read and accept the cancellation policies to
                          continue:
                        </span>
                        <br />
                        {bookRoomsData.map((bookroom, index) => (
                          <React.Fragment key={index}>
                            <span className="fw-bold">
                              {bookroom.mydata.room.roomName} -{" "}
                              {bookroom.mydata.plan.name}
                            </span>
                            <br />
                            <span>
                              {generateCancellationPolicy(
                                bookroom.mydata.ratePlanData,
                                checkIn
                              )}
                            </span>
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                      {currentStep === 1 && (
                        <>
                          <div className="form-check">
                            <div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="cancellationPolicy"
                                checked={isPolicyChecked}
                                onChange={handlePolicyCheckboxChange}
                              />
                              <label
                                htmlFor="cancellationPolicy"
                                className="form-check-label"
                              >
                                I understand and agree to the cancellation
                                policies
                              </label>
                            </div>
                          </div>
                          {policyError && (
                            <p className="text-small" style={{ color: "red" }}>
                              {policyError}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-between mt-2">
                  {currentStep === 1 && (
                    <UpdateGuests roomGuests={roomGuests} />
                  )}
                  {currentStep > 1 &&
                    pLoading !== "paid" &&
                    pLoading !== "complete" && (
                      <>
                        <button
                          className="btn btn-secondary"
                          onClick={handlePrevStep}
                        >
                          Previous
                        </button>
                      </>
                    )}
                  {currentStep < 3 && (
                    <button
                      className="btn btn-primary"
                      onClick={handleNextStep}
                      disabled={currentStep === 1 && !isPolicyChecked}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 3 && pLoading !== "complete" && (
                    <>
                      {pLoading === "pending" && (
                        <Spinner animation="border" size="sm" />
                      )}

                      {/* <button
                        className="btn btn-success"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button> */}
                    </>
                  )}
                </div>
                <>
                  {currentStep === 3 && pLoading === "paid" && (
                    <>
                      <ConfirmBookingComponent
                        orderNo={orderNo}
                        onProcess={handlePaymentProcess}
                      />
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
