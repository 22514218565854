import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverUrl, appKey } from "../Controllers/Config";

const PersonalInfoStep = ({
  userData,
  validationErrors,
  roomGuests,
  onGuestsDataChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [roomGuestsData, setRoomGuestsData] = useState([]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/myprofile`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });
        setUserProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile data:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(userProfile).length > 0) {
      const updatedRoomGuestsData = roomGuests.map((room, index) => {
        if (index === 0) {
          // Prefill lead guest info
          return {
            guests: [
              {
                birthDate: userProfile.dob.split("T")[0],
                contact: {
                  address: userProfile.address,
                  city: userProfile.city,
                  country: userProfile.country,
                  email: userProfile.email,
                  phone: userProfile.phoneNumber,
                  state: userProfile.state,
                  zip: userProfile.zipcode,
                },
                name: {
                  first: userProfile.firstname,
                  last: userProfile.lastname,
                },
                title: userProfile.title,
              },
              ...Array.from({ length: room.adults - 1 }).map(() => ({
                title: "Mr",
                name: { first: "", last: "" },
                birthDate: "",
              })),
              ...room.children.map((age) => ({
                title: "Mr",
                name: { first: "", last: "" },
                birthDate: calculateDOB(age),
              })),
            ],
            specialRequests: "",
          };
        } else {
          return {
            guests: [
              ...Array.from({ length: room.adults }).map(() => ({
                title: "Mr",
                name: { first: "", last: "" },
                birthDate: "",
              })),
              ...room.children.map((age) => ({
                title: "Mr",
                name: { first: "", last: "" },
                birthDate: calculateDOB(age),
              })),
            ],
            specialRequests: "",
          };
        }
      });

      setRoomGuestsData(updatedRoomGuestsData);
    }
  }, [loading, userProfile, roomGuests]);

  useEffect(() => {
    onGuestsDataChange(roomGuestsData);
  }, [roomGuestsData, onGuestsDataChange]);

  const handleGuestDataChange = (e, roomIndex, guestIndex) => {
    const { name, value } = e.target;
    const updatedRoomGuestsData = [...roomGuestsData];
    if (!updatedRoomGuestsData[roomIndex]) {
      updatedRoomGuestsData[roomIndex] = { guests: [], specialRequests: "" };
    }
    if (name === "specialRequests") {
      updatedRoomGuestsData[roomIndex].specialRequests = value;
    } else {
      if (!updatedRoomGuestsData[roomIndex].guests[guestIndex]) {
        updatedRoomGuestsData[roomIndex].guests[guestIndex] = {
          title: "Mr",
          name: { first: "", last: "" },
          birthDate: "",
        };
      }
      if (name === "firstName" || name === "lastName") {
        updatedRoomGuestsData[roomIndex].guests[guestIndex].name[
          name === "firstName" ? "first" : "last"
        ] = value;
      } else {
        updatedRoomGuestsData[roomIndex].guests[guestIndex][name] = value;
      }
    }
    setRoomGuestsData(updatedRoomGuestsData);
  };

  const calculateDOB = (age) => {
    const today = new Date();
    const birthDate = new Date(
      today.getFullYear() - age,
      today.getMonth(),
      today.getDate()
    );
    return birthDate.toISOString().split("T")[0];
  };

  return (
    <div>
      <h3>Personal Information</h3>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {roomGuests.map((room, roomIndex) => (
            <div key={roomIndex} className="mt-4">
              <h4>Room {roomIndex + 1}</h4>
              {roomIndex === 0 ? (
                <div>
                  <p>Lead Guest (Already filled)</p>
                  <div>
                    {/* Display adult fields starting from index 1 for roomIndex 0 */}
                    {Array.from({ length: room.adults }).map(
                      (_, adultIndex) => {
                        if (roomIndex === 0 && adultIndex === 0) {
                          return null; // Skip the first adult input for roomIndex 0
                        }
                        return (
                          <div key={adultIndex} className="row">
                            <h5>Adult {adultIndex + 1}</h5>
                            <div className="form-group col-md-4">
                              <label
                                htmlFor={`adult-firstname-${roomIndex}-${adultIndex}`}
                              >
                                First Name:
                              </label>
                              <input
                                type="text"
                                id={`adult-firstname-${roomIndex}-${adultIndex}`}
                                className="form-control"
                                name="firstName"
                                value={
                                  roomGuestsData[roomIndex]?.guests[adultIndex]
                                    ?.name.first || ""
                                }
                                onChange={(e) =>
                                  handleGuestDataChange(
                                    e,
                                    roomIndex,
                                    adultIndex
                                  )
                                }
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                htmlFor={`adult-lastname-${roomIndex}-${adultIndex}`}
                              >
                                Last Name:
                              </label>
                              <input
                                type="text"
                                id={`adult-lastname-${roomIndex}-${adultIndex}`}
                                className="form-control"
                                name="lastName"
                                value={
                                  roomGuestsData[roomIndex]?.guests[adultIndex]
                                    ?.name.last || ""
                                }
                                onChange={(e) =>
                                  handleGuestDataChange(
                                    e,
                                    roomIndex,
                                    adultIndex
                                  )
                                }
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                htmlFor={`adult-dob-${roomIndex}-${adultIndex}`}
                              >
                                Date of Birth:
                              </label>
                              <input
                                type="date"
                                id={`adult-dob-${roomIndex}-${adultIndex}`}
                                className="form-control"
                                name="birthDate"
                                value={
                                  roomGuestsData[roomIndex]?.guests[adultIndex]
                                    ?.birthDate || ""
                                }
                                onChange={(e) =>
                                  handleGuestDataChange(
                                    e,
                                    roomIndex,
                                    adultIndex
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {Array.from({ length: room.adults }).map((_, adultIndex) => (
                    <div key={adultIndex} className="row">
                      <h5>Adult {adultIndex + 1}</h5>
                      <div className="form-group col-md-4">
                        <label
                          htmlFor={`adult-firstname-${roomIndex}-${adultIndex}`}
                        >
                          First Name:
                        </label>
                        <input
                          type="text"
                          id={`adult-firstname-${roomIndex}-${adultIndex}`}
                          className="form-control"
                          name="firstName"
                          value={
                            roomGuestsData[roomIndex]?.guests[adultIndex]?.name
                              .first || ""
                          }
                          onChange={(e) =>
                            handleGuestDataChange(e, roomIndex, adultIndex)
                          }
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label
                          htmlFor={`adult-lastname-${roomIndex}-${adultIndex}`}
                        >
                          Last Name:
                        </label>
                        <input
                          type="text"
                          id={`adult-lastname-${roomIndex}-${adultIndex}`}
                          className="form-control"
                          name="lastName"
                          value={
                            roomGuestsData[roomIndex]?.guests[adultIndex]?.name
                              .last || ""
                          }
                          onChange={(e) =>
                            handleGuestDataChange(e, roomIndex, adultIndex)
                          }
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor={`adult-dob-${roomIndex}-${adultIndex}`}>
                          Date of Birth:
                        </label>
                        <input
                          type="date"
                          id={`adult-dob-${roomIndex}-${adultIndex}`}
                          className="form-control"
                          name="birthDate"
                          value={
                            roomGuestsData[roomIndex]?.guests[adultIndex]
                              ?.birthDate || ""
                          }
                          onChange={(e) =>
                            handleGuestDataChange(e, roomIndex, adultIndex)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* Render child inputs */}
              {room.children.map((age, childIndex) => (
                <div key={childIndex} className="row">
                  <h5>Child {childIndex + 1}</h5>
                  <div className="form-group col-md-4">
                    <label
                      htmlFor={`child-firstname-${roomIndex}-${childIndex}`}
                    >
                      First Name:
                    </label>
                    <input
                      type="text"
                      id={`child-firstname-${roomIndex}-${childIndex}`}
                      className="form-control"
                      name="firstName"
                      value={
                        roomGuestsData[roomIndex]?.guests[
                          room.adults + childIndex
                        ]?.name.first || ""
                      }
                      onChange={(e) =>
                        handleGuestDataChange(
                          e,
                          roomIndex,
                          room.adults + childIndex
                        )
                      }
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label
                      htmlFor={`child-lastname-${roomIndex}-${childIndex}`}
                    >
                      Last Name:
                    </label>
                    <input
                      type="text"
                      id={`child-lastname-${roomIndex}-${childIndex}`}
                      className="form-control"
                      name="lastName"
                      value={
                        roomGuestsData[roomIndex]?.guests[
                          room.adults + childIndex
                        ]?.name.last || ""
                      }
                      onChange={(e) =>
                        handleGuestDataChange(
                          e,
                          roomIndex,
                          room.adults + childIndex
                        )
                      }
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor={`child-dob-${roomIndex}-${childIndex}`}>
                      Date of Birth:
                    </label>
                    <input
                      type="date"
                      id={`child-dob-${roomIndex}-${childIndex}`}
                      className="form-control"
                      name="birthDate"
                      value={
                        roomGuestsData[roomIndex]?.guests[
                          room.adults + childIndex
                        ]?.birthDate || ""
                      }
                      onChange={(e) =>
                        handleGuestDataChange(
                          e,
                          roomIndex,
                          room.adults + childIndex
                        )
                      }
                    />
                  </div>
                </div>
              ))}
              <div className="form-group">
                <label htmlFor={`specialRequests-${roomIndex}`}>
                  Special Requests for room {roomIndex + 1}:
                </label>
                <textarea
                  id={`specialRequests-${roomIndex}`}
                  className="form-control"
                  name="specialRequests"
                  value={roomGuestsData[roomIndex]?.specialRequests || ""}
                  onChange={(e) => handleGuestDataChange(e, roomIndex)}
                ></textarea>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default PersonalInfoStep;
