import React, { useState } from "react";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";

const AuthForm = () => {
  const [actionType, setActionType] = useState(0);

  const toggleActionType = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setActionType((prevType) => (prevType === 0 ? 1 : 0));
  };

  return (
    <>
      {actionType === 0 ? (
        <>
          <p className="fw-bold">Create an account</p>
          <RegisterForm registerType="auth" />
          <p>
            Already have an account?{" "}
            <a href="#" onClick={toggleActionType}>
              Login
            </a>
          </p>
        </>
      ) : (
        <>
          <p className="fw-bold">Login to your account</p>
          <LoginForm loginType="auth" />
          <p>
            Don't have an account yet?{" "}
            <a href="#" onClick={toggleActionType}>
              Register
            </a>
          </p>
        </>
      )}
    </>
  );
};

export default AuthForm;
