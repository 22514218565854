import React, { useContext, useState } from "react";
import axios from "axios";
import { serverUrl, appKey } from "../../components/Controllers/Config";
import { AuthContext } from "../utils/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("hp_jwt");
      const response = await axios.post(
        `${serverUrl}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        }
      );

      console.log("Logout successful:", response.data);
      toast.success("Logout successful");
      logout();
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Error logging out");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isAuthenticated && (
        <button
          className="btn btn-danger"
          onClick={handleLogout}
          disabled={loading}
        >
          {loading ? "Logging out..." : "Logout"}
        </button>
      )}
    </>
  );
};

export default LogoutButton;
