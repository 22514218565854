import React from "react";
import { Link, useParams } from "react-router-dom";
import PageLayout from "../components/layout/pageLayout";
import ResetPasswordForm from "../components/forms/ResetPasswordForm"; 
import UpdatePasswordForm from "../components/forms/UpdatePasswordForm";

const PasswordReset = () => {
  const { ResetToken } = useParams();

  return (
    <PageLayout pageTitle="Password Reset">
      <section className="faq-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="card">
                <div className="card-body">
                  <h2>Account Recovery</h2>
                  <hr />
                  {!ResetToken ? (
                    <ResetPasswordForm />
                  ) : (
                    <UpdatePasswordForm  />
                  )}
                  <hr />
                  <p>
                    Remember your password? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default PasswordReset;
