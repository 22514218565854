import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../components/layout/pageLayout";
import RegisterForm from "../components/forms/RegisterForm";

const Login = () => (
  <PageLayout pageTitle="Register">
    <section className="faq-section py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body">
                <h2> Getting Started</h2>
                <hr></hr>
                <RegisterForm/>
                <hr></hr>
                <p>
                  Already have an account{" "}
                  <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
);

export default Login;
