import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../components/layout/pageLayout";
import LoginForm from "../components/forms/LoginForm";

const Login = () => (
  <PageLayout pageTitle="Login">
    <section className="faq-section py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body">
                <h2> Welcome Back</h2>
                <hr></hr>
                <LoginForm />
                <hr></hr>
                <p>
                  Having trouble remembering your password?{" "}
                  <Link to="/passwordreset">Reset password</Link>
                </p>
                <p>
                  Don't have an account yet?{" "}
                  <Link to="/register">Register</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
);

export default Login;
