import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashLayout from "../../../components/layout/dashLayout";
import { serverUrl, appKey } from "../../../components/Controllers/Config";
import CancelBookingForm from "../../../components/forms/CancelBookingForm";
import { Alert } from "react-bootstrap";

const CancelBooking = () => {
  const { bookingRef } = useParams();
  const [loading, setLoading] = useState(true);
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(
          `${serverUrl}/hyperguest/bookingInfo/${bookingRef}`,
          {
            headers: {
              Authorization: `${token}`,
              APPKEY: `${appKey}`,
            },
          }
        );
        const fetchedBookingData = response.data;
        // Parse the b_response field
        if (fetchedBookingData.b_response) {
          fetchedBookingData.b_response = JSON.parse(
            fetchedBookingData.b_response
          );
        }
        setBookingData(fetchedBookingData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching booking data:", error);
        setLoading(false);
      }
    };

    fetchBookingData();
  }, [bookingRef]);

  return (
    <DashLayout pageTitle="Booking">
      <section className="faq-section py-5">
        <div className="container">
          <h4>Booking Cancellation Confirmation</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    {bookingData.b_status === "Active" ? (
                      <>
                        <h5>
                          Kindly confirm why you want to cancel the reservation
                          ({bookingData.b_reference})
                        </h5>
                        <CancelBookingForm bookingData={bookingData} />
                      </>
                    ) : (
                      <Alert variant="success">
                        The booking ({bookingData.b_reference}) has already been
                        cancelled successfully.
                      </Alert>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default CancelBooking;
