import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import DashLayout from "../../components/layout/dashLayout";
import { serverUrl, appKey, formatDate } from "../../components/Controllers/Config";

const MyCancellations = () => {
  const [loading, setLoading] = useState(true);
  const [myCancellations, setCancellations] = useState({});

  useEffect(() => {
    const fetchCancellations = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/mycancellations`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });
        setCancellations(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cancelled booking data:", error);
        setLoading(false);
      }
    };

    fetchCancellations();
  }, []);

const columns = [
  {
    Header: "#",
    accessor: "cancellation_id",
  },
  {
    Header: "Booking Ref",
    accessor: "c_bookingref",
    Cell: ({ value }) => (
      <div>
        <a href={`/dashboard/mybookings/${value}/view`}>{value}</a>
      </div>
    ),
  },
  {
    Header: "Reason",
    accessor: "c_reason",
  },
  {
    Header: "Status",
    accessor: "c_status",
  },
  {
    Header: "Date Requested",
    accessor: "c_daterequested",
    Cell: ({ value }) => formatDate(value),
  },
];

  return (
    <DashLayout pageTitle="My Cancellations">
      <section className="faq-section py-5">
        <div className="container">
          <h4>Cancelled Bookings Requests</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <ReactTable
                      data={myCancellations}
                      columns={columns}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default MyCancellations;
