
import React from 'react';

const Carousel = ({  }) => {
    
    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner" style={{ }}>
                <div className="carousel-item active">
                    <img
                        src="https://image-tc.galaxy.tf/wijpeg-15ye9lfjdmt8y21g81yg7wadc/img-9800a.jpg?width=1920&height=840"
                        alt="First slide"
                        className="d-block w-100"
                    />
                </div>
                <div className="carousel-item">
                    <img
                        src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/445751092.jpg?k=87311c6227b6cc95ab9bbaaf521d71d9077fdc2a4826a7adfcea34c519954ca7&o=&hp=1"
                        alt="Second slide"
                        className="d-block w-100"
                    />
                </div>
                <div className="carousel-item">
                    <img
                        src="https://hotelxtoronto.com/_novaimg/4906918-1481330_0_0_2200_1200_2200_1200.rc.jpg"
                        alt="Third slide"
                        className="d-block w-100"
                    />
                </div>


            </div>
            <a
                className="carousel-control-prev"
                href="#myCarousel"
                role="button"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </a>
            <a
                className="carousel-control-next"
                href="#myCarousel"
                role="button"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" ariahidden="true"></span>
                <span className="visually-hidden">Next</span>
            </a>
        </div>
    );
};


export default Carousel;
