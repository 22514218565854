import React from "react";
import paymentMethods from "./PaymentMehtods";


const AccordionPayment = ({
  bookRoomsData,
  requestData,
  onProcess,
  orderNo,
}) => {
  return (
    <div>
      <div className="accordion" id="accordionExample">
        {paymentMethods.map((method, index) => (
          <div className="accordion-item" key={method.id}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                PAY VIA {method.name}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${
                index === 0 ? "show" : ""
              }`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <img
                  src={method.image}
                  alt={method.name}
                  style={{ width: "auto", height: "50px" }}
                />
                <method.component
                  bookRoomsData={bookRoomsData}
                  requestData={requestData}
                  onProcess={onProcess}
                  orderNo={orderNo}
                />{" "}
                {/* Dynamically render the component */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionPayment;
