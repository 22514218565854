import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashLayout from "../../../components/layout/dashLayout";
import { serverUrl, appKey } from "../../../components/Controllers/Config";
import ImageGallery from "react-image-gallery";

const ViewBooking = () => {
  const [loading, setLoading] = useState(true);
  const { bookingRef } = useParams();
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(
          `${serverUrl}/hyperguest/viewBooking/${bookingRef}`,
          {
            headers: {
              Authorization: `${token}`,
              APPKEY: `${appKey}`,
            },
          }
        );
        setBookingData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching booking data:", error);
        setLoading(false);
      }
    };

    fetchBookingData();
  }, []);

  return (
    <DashLayout pageTitle="Booking">
      <section className="faq-section py-5">
        <div className="container">
          <h4>Booking Details</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row p-2">
                      <h2>{bookingData.property.name}</h2>
                      <div className="col-md-8 ">
                        <p className="">
                          <i className="fas fa-map-marker-alt"></i>&nbsp;
                          {bookingData.property
                            ? bookingData.property.location
                              ? bookingData.property.location.address
                              : ""
                            : ""}
                        </p>
                        <h5>
                          <strong>Booking Reference:</strong>{" "}
                          {bookingData.booking.b_reference}
                        </h5>
                        <h5>
                          <strong>Booking Date:</strong>{" "}
                          {bookingData.booking.b_response.content.dates.from} to{" "}
                          {bookingData.booking.b_response.content.dates.to}
                        </h5>
                      </div>
                      <div className="col-md-4 ">
                        <div className="">
                          {bookingData.property &&
                            bookingData.property.settings &&
                            bookingData.property.settings.hotelType && (
                              <span className="badge text-bg-dark">
                                {bookingData.property.settings.hotelType.name}
                              </span>
                            )}
                          &nbsp;
                          {[...Array(bookingData.property?.rating || 0)].map(
                            (rating, i) => (
                              <i
                                key={i}
                                className="fas fa-star text-warning"
                              ></i>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <ImageGallery
                    items={bookingData.property.images.map((image) => ({
                      original: image.uri,
                      thumbnail: image.uri,
                    }))}
                    thumbnailPosition={"bottom"}
                    autoPlay
                  />
                </div>
                <div className="col-md-12">
                  {bookingData.property &&
                    bookingData.property.descriptions && (
                      <div>
                        {bookingData.property.descriptions.map(
                          (description, index) => (
                            <p key={index} className="mt-2">
                              {description.description ?? ""}
                            </p>
                          )
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default ViewBooking;
