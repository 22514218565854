import MpesaForm from "../forms/MpesaForm";
import mpesaimg from "../../assets/mpesaa.jpg";

const paymentMethods = [
  {
    id: 1,
    name: "M-Pesa",
    image: mpesaimg,
    component: MpesaForm, // Just the component reference, not JSX
  },
];

export default paymentMethods;
