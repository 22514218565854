import React, { useState, useContext } from "react";
import axios from "axios";
import { serverUrl } from "../Controllers/Config";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResetPassword = async () => {
    if (!email ) {
      setError("Email address is required.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${serverUrl}/auth/reset`, {
        email,
      });

      console.log("ResetPassword successful:", response.data);
      setError(null);
      setSuccess(response.data.message);
    } catch (error) {
      console.error("ResetPassword error:", error);
      console.error("Error response:", error.response);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-12 mb-2">
          <label>Email Address:</label>
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleResetPassword}
            disabled={loading}
          >
            {loading ? "Sending Request..." : "ResetPassword"}
          </button>
          {success && <div className="alert alert-success mt-2">{success}</div>}
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
