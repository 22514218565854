import React, { useEffect, useState } from "react";
import axios from "axios";
import { appKey, serverUrl } from "../Controllers/Config";
import ConfirmPaymentComponent from "./ConfirmPaymentComponent ";
import { Spinner } from "react-bootstrap";

const MpesaForm = ({ bookRoomsData, requestData, onProcess, orderNo }) => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [checkoutRequestID, setCheckoutRequestID] = useState(null);
  const [alertColor, setAlertColor] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+254");
  const [totalAmount, setTotalAmount] = useState(0);
  const roundedAmount = Math.ceil(totalAmount);
  console.log("amount", totalAmount, orderNo);
  console.log("Rounded Amount:", roundedAmount);

  useEffect(() => {
    calculateTotalPayable(bookRoomsData);
    // console.log("bookRoomsData", bookRoomsData);
  }, [bookRoomsData]);

  const calculateTotalPayable = (bookRoomsData) => {
    let totalPayable = 0;
    bookRoomsData.forEach((bookroom) => {
      totalPayable += bookroom.expectedPrice.amount;
    });
    setTotalAmount(totalPayable);
  };

  const handleSubmit = async () => {
    const phoneNumberRegex = /^\+254\d{9}$/;
    console.log(phoneNumber);

    // Use map to return a new array with the room removed
    const bookroomdata = bookRoomsData.map((item) => {
      const { mydata, ...rest } = item;
      // Remove the room from mydata
      const { room, ...restMyData } = mydata;
      return { ...rest, mydata: restMyData };
    });

    // Ensure the phone number matches the required format
    if (!phoneNumber.match(phoneNumberRegex)) {
      setResponseMessage(
        "Invalid phone number format. Please enter a valid Kenyan phone number."
      );
      setAlertColor("alert-danger");
      return;
    }
    setCheckoutRequestID("");
    setLoading(true);
    const token = localStorage.getItem("hp_jwt");
    try {
      const response = await axios.post(
        `${serverUrl}/mpesa/stkPush`,
        {
          PhoneNo: phoneNumber.replace(/^\+/, ""), // Remove the '+' for the request
          Amount: roundedAmount,
          Reference: orderNo,
          bookRoomsData: bookroomdata, // Pass the modified bookroomdata
          requestData: requestData,
        },
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        }
      );
      setCheckoutRequestID(response.data.CheckoutRequestID);
      onProcess("pending");
    } catch (error) {
      setResponseMessage("An error occurred while processing the request.");
      setAlertColor("alert-danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row mt-2">
      {/* Display response message with dynamic alert color */}
      {responseMessage && (
        <div className={`alert ${alertColor}`} role="alert">
          {responseMessage}
        </div>
      )}

      {/* M-Pesa form */}
      <div className="col-md-12 mb-2">
        <input
          type="tel"
          placeholder="Enter your phone number"
          value={phoneNumber}
          onChange={(e) => {
            const value = e.target.value;

            // Allow only valid input format
            if (value.length <= 13 && /^\+254\d{0,9}$/.test(value)) {
              setPhoneNumber(value);
            }
          }}
          maxLength={13} // Maximum length of 12 characters
          className="form-control" // Add Bootstrap classes for styling
        />
      </div>
      <div className="col-md-12 mb-2">
        <button
          className="btn btn-success"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" /> Submitting...
            </>
          ) : (
            "Send STK Push"
          )}
        </button>
      </div>
      {/* ConfirmPaymentComponent */}
      {checkoutRequestID && (
        <ConfirmPaymentComponent
          checkoutRequestID={checkoutRequestID}
          onProcess={onProcess}
        />
      )}
    </div>
  );
};

export default MpesaForm;
