import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import menuItems from "./menuItems"; 
import { AuthContext } from "./utils/AuthContext";
import LogoutButton from "./widgets/LogoutButton";

const Navbar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);

  const filteredMenuItems = menuItems.filter(
    (item) => item.type === 0 || (isAuthenticated && item.type === 1)
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          Hotel Booking App
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            {filteredMenuItems.map((item, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  className="nav-link"
                  exact
                  to={item.link}
                  activeClassName="active"
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="d-flex">
            {isAuthenticated ? (
              <LogoutButton/>
            ) : (
              <>
                <NavLink className="btn btn-outline-light me-2" to="/login">
                  Login
                </NavLink>
                <NavLink className="btn btn-light" to="/register">
                  Register
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
