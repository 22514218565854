import React, { useState } from "react";
import axios from "axios";
import { serverUrl } from "../Controllers/Config";
import { useParams } from "react-router-dom";

const UpdatePasswordForm = () => {
  const { ResetToken } = useParams();
  const [resetcode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async () => {
    if (!resetcode || !password || !cpassword) {
      setError("All fields are required.");
      return;
    }
    if (password !== cpassword) {
      setError("Passwords do not match.");
      return;
    }
    const requestData = {
      code: resetcode,
      password,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${serverUrl}/auth/updatepassword/${ResetToken}`,
        requestData
      );

      console.log("Update Password successful:", response.data);
      setError(null);
      setSuccess(response.data.message);
    } catch (error) {
      console.error("Update Password error:", error);
      console.error("Error response:", error.response);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-12 mb-2">
          <label>Reset Code:</label>
          <input
            type="text"
            className="form-control"
            value={resetcode}
            onChange={(e) => setResetCode(e.target.value)}
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Confirm Password:</label>
          <input
            type="password" // Corrected input type
            className="form-control"
            value={cpassword}
            onChange={(e) => setCPassword(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdatePassword}
            disabled={loading}
          >
            {loading ? "Sending Request..." : "Update Password"}
          </button>
          {success && <div className="alert alert-success mt-2">{success}</div>}
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export default UpdatePasswordForm;
