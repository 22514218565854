import React, { useState, useEffect } from "react";
import PageLayout from "../components/layout/pageLayout";
import { serverUrl } from "../components/Controllers/Config";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

const FAQs = () => {
  const [faqData, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${serverUrl}/app/faqs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setFaqs(data);
        } else {
          setError("Error fetching FAQs. Please try again later.");
        }
      } catch (error) {
        setError("An error occurred while fetching the FAQs.");
      } finally {
        setLoading(false); // Set loading to false after fetching is complete
      }
    };

    fetchFaqs();
  }, []);

  return (
    <PageLayout pageTitle="Frequently Asked Questions">
      <section className="faq-section py-5">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Loading FAQs...</p>
            </div>
          ) : error ? (
            <Alert variant="danger" className="text-center">
              {error}
            </Alert>
          ) : faqData.length > 0 ? (
            faqData.map((faq, index) => (
              <div key={index} className="mb-4">
                <h3 className="faq-question">{faq.f_question}</h3>
                <div
                  className="faq-answer"
                  dangerouslySetInnerHTML={{
                    __html: faq.f_answer,
                  }}
                ></div>
              </div>
            ))
          ) : (
            <div className="alert alert-warning">No FAQs available at the moment.</div>
          )}
        </div>
      </section>
    </PageLayout>
  );
};

export default FAQs;
