import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-auto py-3">
      <div className="container text-center">
        <p>&copy; {currentYear} Your Hotel Booking App</p>
      </div>
    </footer>
  );
};

export default Footer;
