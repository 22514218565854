// ContactUs.js
import React from "react";
import PageLayout from "../components/layout/pageLayout";


const ContactUs = () => {
  return (
    <PageLayout pageTitle="Contact Us">
      <main>
        <section className="contact-info my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Contact Information</h2>
                <p>If you have any questions, feel free to reach out to us:</p>
                <ul className="list-unstyled">
                  <li>
                    <strong>Address:</strong> 123 Main Street, Anytown, USA
                  </li>
                  <li>
                    <strong>Phone:</strong> (123) 456-7890
                  </li>
                  <li>
                    <strong>Email:</strong> info@hotelbooking.com
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h2>Send Us a Message</h2>
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="5"
                      placeholder="Your Message"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

      </main>
    </PageLayout>
  );
};

export default ContactUs;
