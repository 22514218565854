import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import DashLayout from "../../components/layout/dashLayout";
import {
  serverUrl,
  appKey,
  formatDate,
} from "../../components/Controllers/Config";

const MyBookings = () => {
  const [loading, setLoading] = useState(true);
  const [myBookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/mybookings`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });

        const bookingsWithDetails = response.data.map((booking) => {
          const bookingDetails = JSON.parse(booking.b_booking);
          const bookingResponse = JSON.parse(booking.b_response);

          return {
            ...booking,
            bookingDetails: {
              dates: bookingDetails.dates,
              propertyId: bookingDetails.propertyId,
              bookingId: bookingResponse?.content?.bookingId || "Unknown ID",
            },
            status: booking.b_status, // Use the b_status field for status
          };
        });

        setBookings(bookingsWithDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching booking data:", error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const columns = [
    {
      Header: "Booking ID",
      accessor: "bookingDetails.bookingId",
      Cell: ({ value }) => <p>{value}</p>,
    },
    {
      Header: "Booking Ref",
      accessor: "b_reference",
    },
    {
      Header: "Booking Details",
      accessor: "bookingDetails",
      Cell: ({ value }) => (
        <div>
          <p>
            Dates:{" "}
            <span className="text-small">
              {value.dates.from} to {value.dates.to}
            </span>
          </p>
          <p>Hotel ID: {value.propertyId}</p>
        </div>
      ),
    },
    {
      Header: "Booking Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`badge ${value === "Active" ? "bg-success" : "bg-danger"}`}
        >
          {value === "Active" ? "Confirmed" : "Cancelled"}
        </span>
      ),
    },
    {
      Header: "Date Booked",
      accessor: "b_datecreated",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Action",
      accessor: "b_reference",
      Cell: ({ value }) => (
        <div>
          <a
            href={`/dashboard/mybookings/${value}/view`}
            className="btn btn-primary btn-sm"
            style={{ marginRight: "10px" }}
          >
            View
          </a>
          <a
            href={`/dashboard/mybookings/${value}/cancel`}
            className="btn btn-danger btn-sm"
          >
            Cancel
          </a>
        </div>
      ),
    },
  ];

  return (
    <DashLayout pageTitle="My Bookings">
      <section className="faq-section py-5">
        <div className="container">
          <h4>My Bookings Requests</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <ReactTable
                      data={myBookings}
                      columns={columns}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default MyBookings;
