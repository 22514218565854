import React, { useState, useEffect } from "react";
import Carousel from "../components/widgets/Carousel";
import SearchForm from "../components/widgets/SearchForm";
import SearchResults from "../components/widgets/SearchResults";

const Home = ({ searchOptions, searchData, siteData }) => {
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(null);
  const [hotelLocations, setHotelLocations] = useState([]);

  useEffect(() => {
    if (!siteData || siteData.length === 0) {
      console.log("siteData is empty");
    } else {
      setHotelLocations(siteData);
    }
  }, [siteData]);

  const handleSearchOptions = (data) => {
    console.log("Search Options::", data);
    setSearchFilters(data);
  };

  const handleSearchData = (results) => {
    console.log("Search Results::", results);
    setSearchResults(results);
  };

  const handleError = (error) => {
    console.error("Error occurred::", error);
    setError(error);
  };

  const handleSearching = (bool) => {
    setSearching(bool);
  };

  const getDefaultLocationFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("location") || "";
  };

  return (
    <div>
      <main>
        <Carousel />

        <h4 className="text-center">Get accommodation now</h4>
        <div className="container searchform">
          <SearchForm
            defaultLocation={getDefaultLocationFromUrl()}
            onSearch={async (results) => {
              handleSearchData(results);
            }}
            onError={handleError}
            onSearchOptions={async (options) => {
              handleSearchOptions(options);
            }}
            onSearching={handleSearching}
            hotelLocations={hotelLocations}
          />
        </div>
        <div className="searchresults">
          <div className="container">
            {searching ? (
              <p>Searching...</p>
            ) : error ? (
              <div className="alert alert-danger">
                <p>{error.error}</p>
              </div>
            ) : searchResults.length > 0 ? (
              <SearchResults results={searchResults} options={searchFilters} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
