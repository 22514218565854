import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { appKey, serverUrl } from "../Controllers/Config";

const ConfirmBookingComponent = ({ orderNo, onProcess }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const ConfirmBooking = async () => {
    const token = localStorage.getItem("hp_jwt");
    try {
      setError(null);
      setLoading(true);
      const response = await axios.post(
        `${serverUrl}/hyperguest/makeBooking`,
        { orderNo }, // Pass data as an object
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess("Booking successful.");
        onProcess("complete");
      } else {
        setError(response.data?.errorDetails?.message || "An error occurred.");
      }
    } catch (error) {
      setError(
        error.response?.data?.errorDetails?.message ||
          error.message ||
          "An error occurred."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ConfirmBooking();
  }, [orderNo]);

  return (
    <>
      {loading ? (
        <>
          <Spinner animation="border" size="sm" /> {"Booking.."}
        </>
      ) : (
        <>
          {success && <div className="alert alert-success mt-2">{success}</div>}
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </>
      )}
    </>
  );
};

export default ConfirmBookingComponent;
