import React, { useState } from "react";
import axios from "axios";
import { serverUrl, appKey } from "../../components/Controllers/Config";

const CancelBookingForm = ({ bookingData }) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
console.log("bookingData", bookingData, bookingData.b_response.content.bookingId);
  const handleCancelBooking = async () => {
    if (!reason) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      const token = localStorage.getItem("hp_jwt");
      const requestData = {
        Reason: reason,
        BookingRef: bookingData.b_reference,
        BookingID: bookingData.b_response.content.bookingId,
      };

      const response = await axios.post(
        `${serverUrl}/hyperguest/cancelbooking`,
        requestData,
        {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        }
      );
      setError(null);
      console.log("Booking cancelled successfully:", response.data);
      setSuccess("Booking cancelled successfully");
    } catch (error) {
      console.error("Cancelling error:", error);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-12 mb-2">
          <label>Reason:</label>
          <textarea
            className="form-control"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleCancelBooking}
          >
            {loading ? "Submitting Request..." : "Cancel Booking"}
          </button>
          {error && <div className="alert alert-danger mt-2">{error}</div>}
          {success && <div className="alert alert-success mt-2">{success}</div>}
        </div>
      </div>
    </form>
  );
};

export default CancelBookingForm;
