import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatAmount, hotelBoards } from "../Controllers/Config";

const Room = ({
  room,
  propertyinfo,
  bookRoomsData,
  setBookRoomsData,
  totalRooms,
  roomGuests,
  totalGuests,
}) => {
  const { name, type, images, settings, beds, ratePlans } = room;
  const [roomsCount, setRoomsCount] = useState({});

  const mainroomData = propertyinfo
    ? propertyinfo.rooms.find((r) => r.roomId === room.id)
    : null;

  const getRatePlanData = (planId) => {
    if (mainroomData && mainroomData.ratePlans) {
      return mainroomData.ratePlans.find(
        (ratePlan) => ratePlan.ratePlanId === planId
      );
    }
    return null;
  };

  // Calculate the number of rooms that can still be selected
  const calculateAvailableRooms = () => {
    const bookedRoomsCount = bookRoomsData.reduce((acc, booking) => {
      if (booking.roomId === mainroomData.roomId) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return totalRooms - bookedRoomsCount;
  };


  // Check if total rooms have been selected
  const isTotalRoomsSelected = () => {
    return bookRoomsData.length === totalRooms;
  };

  const handleRoomsChange = (plan, ratePlanData, event) => {
    const { value } = event.target;
    const planId = plan.id;
    const amount = ratePlanData.payment.chargeAmount.searchCurrency;
    const amountMain = ratePlanData.payment.chargeAmount.price;
    const currency = ratePlanData.payment.chargeAmount.currency;
    const ratePlanCode = ratePlanData.ratePlanCode;
    const count = parseInt(value);
    const key = `${mainroomData.roomId}_${mainroomData.roomTypeCode}_${ratePlanCode}`;

    const newRoomsCount = {
      ...roomsCount,
      [key]: count,
    };

    setRoomsCount(newRoomsCount);

    let updatedBookRoomsData = [...bookRoomsData];

    // Remove existing rooms for this plan if count is set to 0
    if (count === 0) {
      updatedBookRoomsData = updatedBookRoomsData.filter(
        (r) =>
          !(
            r.rateCode === ratePlanCode &&
            r.roomCode === mainroomData.roomTypeCode &&
            r.roomId === mainroomData.roomId
          )
      );
    } else {
      // Add or update rooms based on the new count
      const roomData = {
        roomId: mainroomData.roomId,
        roomCode: mainroomData.roomTypeCode,
        rateCode: ratePlanCode,
        expectedPrice: {
          amount: count * amount,
          currency: 'KES',
          amountMain: count * amountMain,
          currencyMain: currency,
        },
        guests: [],
        specialRequests: [],
        mydata: {
          room: mainroomData,
          plan: plan,
          ratePlanData: ratePlanData,
        },
      };

      // Find existing rooms for this plan and remove them
      updatedBookRoomsData = updatedBookRoomsData.filter(
        (r) =>
          !(
            r.rateCode === ratePlanCode &&
            r.roomCode === mainroomData.roomTypeCode &&
            r.roomId === mainroomData.roomId
          )
      );

      // Add new rooms if count is not 0
      updatedBookRoomsData.push(...Array(count).fill(roomData));
    }

    // Check if the number of rooms selected matches the totalRooms
    if (
      updatedBookRoomsData.length > totalRooms ||
      updatedBookRoomsData.length > room.numberOfAvailableRooms
    ) {
      toast.error(
        `Please select exactly ${totalRooms} rooms for your booking.`,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } else {
      // Update bookRoomsData only if the count matches totalRooms
      setBookRoomsData(updatedBookRoomsData);
    }
  };

  const generateBedText = () => {
    if (beds.length === 0) {
      return "No beds specified";
    } else if (beds.length === 1 && beds[0].type) {
      return `${beds[0].quantity} ${beds[0].type} Bed${
        beds[0].quantity > 1 ? "s" : ""
      }`;
    } else {
      const filteredBeds = beds.filter((bed) => bed.type !== null);
      if (filteredBeds.length === 0) {
        return "No beds specified";
      }

      const bedTypes = filteredBeds.map(
        (bed) => `${bed.quantity} ${bed.type} Bed${bed.quantity > 1 ? "s" : ""}`
      );
      return bedTypes.join(", ");
    }
  };

  if (ratePlans.length === 0) {
    return null; // Don't render the room if there are no rate plans
  }

  const generateBoard = (board) => {
    const boardItem = hotelBoards.find((item) => item.initial === board);
    return boardItem ? boardItem.value : "No Board Info"; 
  };


  const availableRooms = calculateAvailableRooms();
  const totalRoomsSelected = isTotalRoomsSelected();

  return (
    <div className="room-container">
      <div className="row">
        <div className="col-md-4">
          {images.length > 0 && (
            <div className="room-image">
              <img src={images[0].uri} alt={name} className="roomimg" />
            </div>
          )}
          <div className="room-details">
            <h6>{name}</h6>
            {generateBedText() && <p>{generateBedText()}</p>}
            <p>
              <strong>Max</strong> {settings.maxOccupancy} Guests
            </p>
            <p>
              <strong>Max</strong> {settings.adultsNumber} Adults
            </p>
            <p>
              <strong>Max</strong> {settings.childrenNumber} Child
            </p>
            
          </div>
        </div>
        <div className="col-md-8">
          <div className="rate-plans">
            <h6>Rate Plans:</h6>
            <ul className="list-group list-group-flush">
              {ratePlans
                .filter((plan) => plan !== null)
                .map((plan) => {
                  const ratePlanData = getRatePlanData(plan.id);
                  if (!ratePlanData) {
                    return null;
                  }
                  return (
                    <li key={plan.id} className="list-group-item">
                      <strong
                        data-tooltip-content={plan.description}
                        data-tooltip-id={`tooltip-${plan.id}`}
                      >
                        {plan.name}{" "}
                      </strong>
                      <Tooltip
                        id={`tooltip-${plan.id}`}
                        place="top"
                        effect="solid"
                      />
                      {ratePlanData &&
                        ratePlanData.prices &&
                        ratePlanData.prices[
                                    ratePlanData.payment
                                      .chargeType
                                  ] && (
                          <>
                            <span className="badge bg-dark">
                              {generateBoard(ratePlanData.board)}
                            </span>
                            <p className="fw-bold mb-0">
                              {ratePlanData.payment.chargeAmount.currency}{" "}
                              {formatAmount(ratePlanData.payment.chargeAmount.price)}
                              {" (KES"}
                              {formatAmount(
                                ratePlanData.payment.chargeAmount.searchCurrency
                              )}
                              {")"}
                            </p>
                            <div>
                              {ratePlanData.remarks &&
                                ratePlanData.remarks.map((remarkItem) => (
                                  <p className="text-small">{remarkItem}</p>
                                ))}
                            </div>
                            <br />
                            <label>Select Rooms</label>
                            <input
                              type="number"
                              value={
                                roomsCount[
                                  `${mainroomData.roomId}_${mainroomData.roomTypeCode}_${ratePlanData.ratePlanCode}`
                                ] || 0
                              }
                              onChange={(e) =>
                                handleRoomsChange(plan, ratePlanData, e)
                              }
                              min={0}
                              max={availableRooms} // Set max attribute to limit selection
                              disabled={
                                totalRoomsSelected &&
                                !roomsCount[
                                  `${mainroomData.roomId}_${mainroomData.roomTypeCode}_${ratePlanData.ratePlanCode}`
                                ]
                              } // Disable all inputs if total rooms are selected except for the ones already selected
                              className="form-control"
                            />
                          </>
                        )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Room;
