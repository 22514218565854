import React, { useState, useEffect } from "react";
import axios from "axios";
import DashLayout from "../../components/layout/dashLayout";
import ProfileForm from "../../components/forms/ProfileForm";
import { serverUrl, appKey } from "../../components/Controllers/Config";

const MyProfile = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/myprofile`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return (
    <DashLayout pageTitle="My Profile">
      <section className="faq-section py-5">
        <div className="container">
          <h4>Update Profile</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <ProfileForm userData={userData} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default MyProfile;
