import React, { useState, useEffect } from "react";
import axios from "axios";
import DashLayout from "../../components/layout/dashLayout";
import { serverUrl, appKey } from "../../components/Controllers/Config";

const Dashboard = () => {
  const fname = localStorage.getItem("hp_firstname");
  const [loading, setLoading] = useState(true);
  const [myTotals, setTotals] = useState({});

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/dashboard`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });
        setTotals(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching totals data:", error);
        setLoading(false);
      }
    };

    fetchTotals();
  }, []);

  return (
    <DashLayout pageTitle="My Dashboard">
      <section className="faq-section py-5">
        <div className="container">
          <h4>Hello {fname},</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h2>Total Bookings</h2>
                    <h1 className="fw-bold text-secondary">
                      {myTotals.bookings || 0}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h2>Active Bookings</h2>
                    <h1 className="fw-bold text-secondary">
                      {myTotals.active || 0}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h2>Cancelled Bookings</h2>
                    <h1 className="fw-bold text-secondary">
                      {myTotals.cancelled || 0}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h2>Total Paid</h2>
                    <h1 className="fw-bold text-secondary">
                      KES {myTotals.totalPaid || 0}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default Dashboard;
