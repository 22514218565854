// config.js
export const serverUrl = "https://server.kalielltd.co.ke/api";
export const appKey = "7525AB41CAE83DD7858314958DEAE";
export const placeholderImage = "/assets/placeholder.png";
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiY29sbGluc25qb2thIiwiYSI6ImNrbDdoMW9iMjBmc28ycG5wNGtleHY1eTIifQ.NtWws1dirkvdOacUKGJ8TA";

export const hotelBoards = [
  { initial: "FB", value: "Full Board" },
  { initial: "HB", value: "Half Board" },
  { initial: "BB", value: "Bed & Breakfast" },
  { initial: "AI", value: "All Inclusive" },
  { initial: "RO", value: "Room Only" },
];

export const formatAmount = (amount) => {
  if (amount > 0) {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return null;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${year}/${month}/${day} ${hours}:${minutes} ${ampm}`;
};


export const generateOrderNumber = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const random = Array.from({ length: 8 }, () =>
    characters.charAt(Math.floor(Math.random() * characters.length))
  ).join("");
  return `AO-${random}`;
};

export const generateCancellationPolicy = (ratePlanData, checkIn) => {
  let policyText = "";

  if (ratePlanData && ratePlanData.cancellationPolicies) {
    ratePlanData.cancellationPolicies.forEach((policy, index) => {
      const {
        daysBefore,
        penaltyType,
        amount,
        timeSetting,
        cancellationDeadlineHour,
        currency,
      } = policy;

      let penaltyDescription = "";

      if (penaltyType === "currency") {
        penaltyDescription = `a penalty of ${currency} ${amount} will be charged`;
      } else if (penaltyType === "percent") {
        penaltyDescription = `a penalty of ${amount}% of the total amount will be charged`;
        // Check if it's 100% to indicate non-refundable booking
        if (amount === 100) {
          penaltyDescription += " (Non-refundable)";
        }
      } else if (penaltyType === "nights") {
        penaltyDescription = `a penalty of ${amount} night(s) will be charged`;
      }

      if (
        timeSetting &&
        timeSetting.timeFromCheckInType &&
        timeSetting.timeFromCheckIn &&
        cancellationDeadlineHour &&
        checkIn
      ) {
        const { timeFromCheckInType, timeFromCheckIn } = timeSetting;

        // Parse the checkIn date
        let cancellationDate = new Date(checkIn);

        // Check if the date is valid
        if (isNaN(cancellationDate.getTime())) {
          console.error("Invalid checkIn date:", checkIn);
          return;
        }

        // console.log("Initial cancellationDate:", cancellationDate);

        // Adjust the cancellation date based on the timeFromCheckInType and timeFromCheckIn
        if (timeFromCheckInType === "hours") {
          cancellationDate.setHours(
            cancellationDate.getHours() - timeFromCheckIn
          );
        } else if (timeFromCheckInType === "days") {
          cancellationDate.setDate(
            cancellationDate.getDate() - timeFromCheckIn
          );
        }

        // Set the hours and minutes based on cancellationDeadlineHour
        const [hours, minutes] = cancellationDeadlineHour
          .split(":")
          .map(Number);
        cancellationDate.setHours(hours);
        cancellationDate.setMinutes(minutes);

        // Format the final cancellation date and time
        const formattedDate = cancellationDate.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        // console.log(
        //   "Formatted Date:",
        //   formattedDate,
        //   "Raw Date:",
        //   cancellationDate
        // );

        penaltyDescription += ` if cancelled after ${formattedDate}`;
      }

      // Determine whether the policy applies before or after check-in
      const timingText =
        daysBefore > 0
          ? `Cancellation ${daysBefore} days before check-in`
          : `Cancellation ${Math.abs(daysBefore)} days after check-in`;

      // Append a new line before the next policy if it's not the first one
      if (index > 0) {
        policyText += "\n";
      }

      policyText += `${timingText}: ${penaltyDescription}`;
    });
  }
  return policyText;
};

export const generateRequestData = (
  guestsData,
  bookRoomsData,
  checkIn,
  checkOut,
  propertyId,
  totalAmount,
  totalGuests
) => {
  const leadGuest = guestsData[0].guests[0];

  // Prepare room-specific data
  const roomsData = bookRoomsData.map((room, index) => {
    return {
      roomCode: room.roomCode,
      rateCode: room.rateCode,
      expectedPrice: {
        amount: room.expectedPrice.amountMain || 0,
        currency: room.expectedPrice.currencyMain || "KES",
      },
      guests: guestsData[index] ? guestsData[index].guests : [],
      specialRequests: [guestsData[index]?.specialRequests || ""],
    };
  });

  return {
    totalAmount,
    totalGuests,
    dates: {
      from: checkIn,
      to: checkOut,
    },
    propertyId: propertyId,
    leadGuest: leadGuest,
    rooms: roomsData,
    meta: [],
  };
};
