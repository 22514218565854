const menuItems = [
  { label: "Home", link: "/", type: 0 },
  { label: "Contact Us", link: "/contactus", type: 0 },
  { label: "FAQs", link: "/faqs", type: 0 },
  { label: "Dashboard", link: "/dashboard", type: 1 },
  { label: "My Bookings", link: "/dashboard/mybookings", type: 1 },
  { label: "My Cancellations", link: "/dashboard/mycancellations", type: 1 },
  { label: "My Payments", link: "/dashboard/mypayments", type: 1 },
  { label: "My Profile", link: "/dashboard/myprofile", type: 1 },
];

export default menuItems;
